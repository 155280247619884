@import "_variables.scss";

.preview-link {
  .tutorial-preview {
    height: 100%;
    .title {
      margin-bottom: var(--chakra-space-6-4);
      color: $color-surface-text-blue;
    }
    .preview-text {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      font-size: $font-size-reg;
    }
    .tag {
      text-transform: uppercase;
      text-decoration: none;
      padding: var(--chakra-space-2-4) var(--chakra-space-4)
        var(--chakra-space-2-4) var(--chakra-space-4);
      border-radius: $border-radius-xlarge;
      background: $color-btn-secondary-hover;
      font-family: $font-family-sfmono;
      font-weight: $font-weight-medium;
      color: $color-surface-text-blue;
    }

  }
  &:hover {
    .tutorial-preview{
      background-color: $color-bg-badge;
    }
    text-decoration: none;
    .title {
      color: $color-btn-link-hover;
      text-decoration: underline;
    }
  }
}
