@import "_variables.scss";

.tutorial-grid {
  padding: var(--chakra-space-12-8);
  @media screen and (max-width: $breakpoint-mobile-desktop) {
    padding: var(--chakra-space-6-4);
  }
}

.no-tutorials {
  font-size: x-large;
  font-weight: bold;
  padding: var(--chakra-space-12-8);
}

.filters-button {
  display: none;

  @media (max-width: $breakpoint-mobile-desktop) {
    display: block;
  }

}

.filters-container {
  display: none;

  /* Always show filters on larger screens */
  @media (min-width: 768px) {
    display: block !important;

  }

  @media (max-width: $breakpoint-mobile-desktop) {
    width: 100%;


  }

  &.filters-visible {
    display: block;
    /* Show filters on small screens when toggled */
  }
}



.ais-CurrentRefinements-tutorials {
  display: flex;
  flex-wrap: wrap; // Allow the tags to wrap onto new lines
  gap: 10px;

  span.filters-visible {
    width: 100%;
  }

  .ais-CurrentRefinements-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .ais-CurrentRefinements-item {
      background-color: transparent !important;
      white-space: nowrap; // Ensure the text stays on one line

      .ais-CurrentRefinements-category {
        position: relative; // Ensure that the button is positioned relative to its container
        display: inline-block; // To maintain proper inline behavior
        text-transform: capitalize;
        background-color: $fast-blue-4;
        color: $fast-blue-2;
        font-weight: 700;
        padding: var(--chakra-space-3) var(--chakra-space-5);
        border-radius: 8px;
        font-size: $font-size-small;
        display: flex; // Make sure the content inside behaves flexibly
        align-items: center; // Aligns the text and close button vertically
        width: auto;

        .ais-CurrentRefinements-delete {
          margin-left: auto; // Pushes the button to the far right
          background-color: $color-link-hover-color; // Your custom styles
          color: $color-default-white;
          width: var(--chakra-space-6-4); // Adjust size
          height: var(--chakra-space-6-4);
          font-size: $font-size-11px;
          font-weight: bolder;
          border-radius: 20px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          opacity: 1;
          margin-left: $space-rem-small;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile-desktop) {
    .tutorial-grid {
      display: none; // Hide tutorials when filters are shown
    }

    .filters-visible .tutorial-grid {
      display: block; // Show tutorials when filters are hidden
    }
  }

  // Ensure the previews are always displayed above 768px
  @media (min-width: 768px) {
    .tutorial-grid {
      display: grid; // Always display the tutorials in grid layout
    }
  }

}